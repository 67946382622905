<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlay">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular indeterminate :size="100" :width="7" color="#2DB9D1">
                            <v-img src="/static/icon/favicon.ico" :width="60"> </v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{ fraseOverlay }}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr" />
                        <p class="tblName">{{ nombre_tipo_reporte }}</p>
                    </div>
                    <v-card class="borde-card big elevation-0">
                        <v-card-text class="pa-5">
                            <ValidationObserver ref="form" v-slot="{ invalid, validated }">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="d-flex justify-end align-center text-center"
                                    >
                                        <div class="text-exportar">Exportar en</div>
                                        <v-btn
                                            color="#FFFFFF"
                                            @click="generarReporte('xlsx')"
                                            rught
                                            rounded
                                            class="btnReportes elevation-0 mr-1"
                                        >
                                            <img src="/static/icon/xls.png" style="width: 28px" />
                                        </v-btn>
                                        <v-btn
                                            rounded
                                            class="btnReportes btnBorrar elevation-0"
                                            @click="resetValues()"
                                        >
                                            Borrar campos
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <ValidationProvider v-slot="{errors}" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Clientes"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                class="pa-0 ma-0"
                                                required
                                                :error-messages="errors"
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <multi-select
                                            outlined
                                            :items="registrosPatronales"
                                            item_text="nombreClave"
                                            item_value="id"
                                            @dataMultiple="(data)=> registrosPatronalesSeleccionados = data"
                                            rules="required"
                                            label="Registros patronales"
                                            ref="multRegistroPatronal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <multi-select
                                            outlined
                                            :items="empresas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> empresasSeleccionadas = data"
                                           
                                            label="Empresas"
                                            ref="multEmpresa"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                       <v-autocomplete
                                           outlined
                                           :items="tipos_nominas"
                                           item-text="claveDescripcion"
                                           item-value="id"
                                           v-model="tipo_nomina_id"
                                           label="Tipo de nómina"
                                       />
                                   </v-col>
                                   <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Año fiscal"
                                            :items="items_anios"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="anio_id"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            :items="meses"
                                            item-text="nombre"
                                            item-value="valor"
                                            v-model="mes_value"
                                            label="Mes de acumulación"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_inicio"
                                            label="Fecha inicial"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_fin"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFin"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" xs="12" sm="3" md="3" class=" radio_group_01 radio-contrato" >
                                            <template >
                                                <div class="mb-4">Estatus:</div>
                                            </template>
                                            <v-radio-group v-model="estado_value" row>
                                                <v-radio class="radio-label" label="Cancelado" value="CANCELADO"></v-radio>
                                                <v-radio class="radio-label" label="Tímbrado" value="TIMBRADO"></v-radio>
                                                <v-radio class="radio-label" label="Acumulado" value="ACUMULADO"></v-radio>
                                                <v-radio class="radio-label" label="Generada" value="GENERADA"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import apiAnios from '@/api/anual_fiscal';

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';

import Notify from '@/plugins/notify';

export default {
    components: {
        'multi-select':VuetifyMultipleAutocomplete,
        VuetifyDatePicker,
        'data-table': Datatable
    },
    data(){
        return {
            nombre_tipo_reporte     : "Reporte Finiquitos",
            nombre_reporte          : "",
            urlReporte              : null,
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,
            tipo_nomina_id          : null,
            isSaving                : false,
            overlay                 : false, 
            fraseOverlay    : "Descargando",

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            tipos_nominas                       : [],
            items_anios                         : [],
            registrosPatronalesSeleccionados    : [],
            empresasSeleccionadas               : [],
            anio_id                             : null,
            fecha_inicio                : null,
            fecha_fin                   : null,
            mes_value       : null,
            estado_value   : '',
            meses: [
                {
                    nombre:"Enero",
                    valor: "Enero"
                },
                {
                    nombre:"Febrero",
                    valor: "Febrero"
                },
                {
                    nombre:"Marzo",
                    valor: "Marzo"
                },
                {
                    nombre:"Abril",
                    valor: "Abril"
                },
                {
                    nombre:"Mayo",
                    valor: "Mayo"
                },
                {
                    nombre:"Junio",
                    valor: "Junio"
                },
                {
                    nombre:"Julio",
                    valor: "Julio"
                },
                {
                    nombre:"Agosto",
                    valor: "Agosto"
                },
                {
                    nombre:"Septiembre",
                    valor: "Septiembre"
                },
                {
                    nombre:"Octubre",
                    valor: "Octubre"
                },
                {
                    nombre:"Noviembre",
                    valor: "Noviembre"
                },
                {
                    nombre:"Diciembre",
                    valor: "Diciembre"
                },
            ],
            estados: [
                {
                    nombre: "Cancelado",
                    valor: 'CANCELADO'
                },
                {
                    nombre: "Tímbrado",
                    valor: 'TIMBRADO'
                },
                {
                    nombre: "Acumulado",
                    valor: 'ACUMULADO'
                },
                {
                    nombre: "Generada",
                    valor: 'GENERADA'
                },
            ]

        }
    },
    computed:{
        anioFiscalComputed(){
            let objectAnio = this.items_anios.find(element => element.id == this.anio_id)
            if(objectAnio){
                return objectAnio.nombre
            }
            else{
                return
            }   
        }
    },
    watch: {

        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        registrosPatronalesSeleccionados:{
            deep:true,  
            handler(val){
                this.cambiaRegistroPatronal();
            }
        },
        empresasSeleccionadas:{
            deep:true,  
            handler(val){
                this.getTiposNominas();
                this.getAnios();
            }
        },

    },
    methods: {
        init() {
            this.datosLogin = this.$session.get("usuario")
            this.rol = this.datosLogin.rol.name

            if(this.rol == 'root'){
                this.getClientes()
            }

            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id
            }

            else if(this.rol == 'admin-empresa') {
                this.cliente_value = this.datosLogin.cliente_id
                this.empresasSeleccionadas.push(this.datosLogin.empresa_id)
            }

            else{
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo")
            }
        },

        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.tipos_nominas = [];
            await apiTipoNomina.find(param).then((response) => {
                this.tipos_nominas = response.data;
                this.tipos_nominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        async getAnios() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.items_anios = [];
            await apiAnios.empresasAnios(param).then((response) => {
                this.items_anios = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        async cambiaCliente(cliente) {
           if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await apiRegistroPatronal.find(param).then(response => {
                this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        async cambiaRegistroPatronal(){
            this.registroPatronal_value = null;
            let param = {   activo: true, 
                            paginate: false, 
                            cliente_id: this.cliente_value,
                            arrayRegistroPatronal: this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados)
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }
            await empresaApi.getEmpresas(param).then(response => {
                    this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        resetValues() {

            this.$nextTick(() => { 

                if (this.rol == "root") {
                    this.cliente_value           = null;
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                }

                if (this.rol == "admin") {
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                }

                this.anio_id = null;
                this.mes_value = null;
                this.fecha_inicio       = null;
                this.fecha_fin          = null;
                this.tipo_nomina_id = null;
                this.estado_value = ''
                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFin != undefined || this.$refs.FechaFin != null){
                    this.$refs.FechaFin.resetDate();
                }
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        async generarReporte(type) {
            this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
                    let url = "periodos/reporte_finiquitos";
					this.overlay = true;
                    let self = this;
                    let today = new Date()
                    const FileDownload = require("js-file-download");
                    var axios = require('axios');
                    
                    let parametros = {
                        arrayEmpresa: this.empresasSeleccionadas,
                        arrayRegistroPatronal: this.registrosPatronalesSeleccionados,
                        anio_fiscal_id: this.anio_id,
                        tipo_nomina_id: this.tipo_nomina_id,
                        mes_acumulacion: this.mes_value,
                        fecha_inicial: this.fecha_inicio,
                        fecha_final: this.fecha_fin,
                        estatus: this.estado_value
                    }

                    var nombre =  "Reporte "+this.nombre_tipo_reporte+" "+today.getDate() +
                                    "_" +
                                    (today.getMonth() + 1) +
                                    "_" +
                                    today.getFullYear() +
                                    "_" +
                                    today.getHours() +
                                    "," +
                                    today.getMinutes() +
                                    "," +
                                    today.getSeconds() +"." + type;

                    try {
                        axios({
                            method: "post",
                            url: url,
                            responseType: "blob",
                            data: parametros,
                        }).then(function(response) {
                            self.overlay = false;
                            if (response.status == 200) 
                                FileDownload(response.data,nombre);
                            else 
                                Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.");
                        })
                        .catch(async (error)=>{
                            this.overlay = false;
                            let errorString = error.response.data;
                            if (
                                error.request.responseType === 'blob' &&
                                error.response.data instanceof Blob &&
                                error.response.data.type &&
                                error.response.data.type.toLowerCase().indexOf('json') != -1
                            ){
                                errorString = JSON.parse(await error.response.data.text());
                                console.log(errorString)

                                if(typeof errorString.error === 'string'){
                                    Notify.ErrorToast(errorString.error);
                                }else{
                                    let error = Object.values(errorString.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda+= `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            }; 
                        });
                    } catch (error) {
                        self.overlay = false;
                    }
				}
			});
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.init()
    },
};
</script>

<style scoped>
  .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    .btnReportes.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-transform: none;
        transition: 0.3s;
        height: 38px !important;
        background: #EFEFEF;
        border-radius: 15px;
    }

    .btnReportes.theme--light.v-btn:hover {
        background: #EFEFEF !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        }
    .margen {
        height: 48px;
        margin-bottom: 20px;
        margin-left: 1px;
    }
    .text-exportar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .btnBorrar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }

</style>
